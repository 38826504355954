import React from 'react';
import ArrowsSwapIcon from 'svgs/icons/ic-arrows-swap.svg';
import BoardChartIcon from 'svgs/icons/ic-board-chart.svg';
import GaugeIcon from 'svgs/icons/ic-gauge.svg';
import GlobalSearchIcon from 'svgs/icons/ic-global-search.svg';
import LayoutIcon from 'svgs/icons/ic-layout.svg';
import LayoutCogIcon from 'svgs/icons/ic-layout-cog.svg';
import MonitorMobileIcon from 'svgs/icons/ic-monitor-mobile.svg';
import PuzzleIcon from 'svgs/icons/ic-puzzle.svg';
import ShoppingBagIcon from 'svgs/icons/ic-shopping-bag.svg';
import TimerStartIcon from 'svgs/icons/ic-timer-start.svg';

export const CTA_BENEFITS = [
    'free-consultation',
    'check-if-good-for-business',
    'enterprise-agency',
    'elevate-presence',
    'streamline-workflow',
];

export const BENEFITS_TILES_DATA = [
    {
        icon: <MonitorMobileIcon width={42} height={42} />,
        text: 'view-webflow-agency.benefits-section.flexible-webflow-design.title',
        description:
            'view-webflow-agency.benefits-section.flexible-webflow-design.description',
    },
    {
        icon: <TimerStartIcon width={42} height={42} />,
        text: 'view-webflow-agency.benefits-section.fast-development.title',
        description:
            'view-webflow-agency.benefits-section.fast-development.description',
    },
    {
        icon: <PuzzleIcon width={42} height={42} />,
        text: 'view-webflow-agency.benefits-section.seamless-cms.title',
        description:
            'view-webflow-agency.benefits-section.seamless-cms.description',
    },
    {
        icon: <GaugeIcon width={42} height={42} />,
        text: 'view-webflow-agency.benefits-section.optimized-performance.title',
        description:
            'view-webflow-agency.benefits-section.optimized-performance.description',
    },
    {
        icon: <GlobalSearchIcon width={42} height={42} />,
        text: 'view-webflow-agency.benefits-section.seo-benefits.title',
        description:
            'view-webflow-agency.benefits-section.seo-benefits.description',
    },
];

export const WHY_WEBFLOW_TILES_DATA = [
    {
        icon: <LayoutCogIcon width={42} height={42} />,
        text: 'view-webflow-agency.expertise-section.what-we-offer.custom-website-development.title',
        description:
            'view-webflow-agency.expertise-section.what-we-offer.custom-website-development.description',
    },
    {
        icon: <LayoutIcon width={42} height={42} />,
        text: 'view-webflow-agency.expertise-section.what-we-offer.cms-integration.title',
        description:
            'view-webflow-agency.expertise-section.what-we-offer.cms-integration.description',
    },
    {
        icon: <ArrowsSwapIcon width={42} height={42} />,
        text: 'view-webflow-agency.expertise-section.what-we-offer.migration-services.title',
        description:
            'view-webflow-agency.expertise-section.what-we-offer.migration-services.description',
    },
    {
        icon: <ShoppingBagIcon width={42} height={42} />,
        text: 'view-webflow-agency.expertise-section.what-we-offer.ecommerce-solutions.title',
        description:
            'view-webflow-agency.expertise-section.what-we-offer.ecommerce-solutions.description',
    },
    {
        icon: <BoardChartIcon width={42} height={42} />,
        text: 'view-webflow-agency.expertise-section.what-we-offer.seo-optimization.title',
        description:
            'view-webflow-agency.expertise-section.what-we-offer.seo-optimization.description',
    },
];

export const OUR_PROCESS_DATA = [
    {
        title: 'view-webflow-agency.expertise-section.our-process.discovery-strategy.title',
        desc: 'view-webflow-agency.expertise-section.our-process.discovery-strategy.description',
    },
    {
        title: 'view-webflow-agency.expertise-section.our-process.web-design.title',
        desc: 'view-webflow-agency.expertise-section.our-process.web-design.description',
    },
    {
        title: 'view-webflow-agency.expertise-section.our-process.development.title',
        desc: 'view-webflow-agency.expertise-section.our-process.development.description',
    },
    {
        title: 'view-webflow-agency.expertise-section.our-process.launch-support.title',
        desc: 'view-webflow-agency.expertise-section.our-process.launch-support.description',
    },
];

export const SUCCESS_STORIES = [
    {
        title: 'view-webflow-agency.success-stories-section.saas-company.title',
        description:
            'view-webflow-agency.success-stories-section.saas-company.description',
    },
    {
        title: 'view-webflow-agency.success-stories-section.ecommerce-brand.title',
        description:
            'view-webflow-agency.success-stories-section.ecommerce-brand.description',
    },
    {
        title: 'view-webflow-agency.success-stories-section.fintech-enterprise.title',
        description:
            'view-webflow-agency.success-stories-section.fintech-enterprise.description',
    },
];

export const PERKS = [
    {
        title: 'view-webflow-agency.perks-section.expertise-experience.title',
        text: 'view-webflow-agency.perks-section.expertise-experience.description',
    },
    {
        title: 'view-webflow-agency.perks-section.custom-solutions.title',
        text: 'view-webflow-agency.perks-section.custom-solutions.description',
    },
    {
        title: 'view-webflow-agency.perks-section.proven-results.title',
        text: 'view-webflow-agency.perks-section.proven-results.description',
    },
    {
        title: 'view-webflow-agency.perks-section.end-to-end-service.title',
        text: 'view-webflow-agency.perks-section.end-to-end-service.description',
    },
    {
        title: 'view-webflow-agency.perks-section.enterprise-expertise.title',
        text: 'view-webflow-agency.perks-section.enterprise-expertise.description',
    },
];

export const ADVANTAGES = [
    {
        index: 1,
        section: 'reduced-development-costs',
        paragraphsCount: 1,
    },
    {
        index: 2,
        section: 'scalability',
        paragraphsCount: 1,
    },
    {
        index: 3,
        section: 'improved-collaboration',
        paragraphsCount: 1,
    },
];
