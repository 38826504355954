import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { AnimatedText } from 'components/animated-text';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { motion, useInView } from 'framer-motion';

const SHeader = styled(HeaderSecond)`
    font-weight: 300;
    text-align: center;
`;

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.025,
        },
    },
};

export const CommitmentSection = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const { formatMessage } = useIntl();

    const text = [
        {
            text: formatMessage({
                id: 'view-webflow-agency.commitment-section.title',
            }),
            underlineWordIndexes: [],
        },
    ];

    return (
        <div ref={ref}>
            <Container>
                <motion.div
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={containerVariants}
                >
                    <SHeader>
                        {text.map((item, index) => {
                            return <AnimatedText {...item} key={index} />;
                        })}
                    </SHeader>
                </motion.div>
            </Container>
        </div>
    );
};
