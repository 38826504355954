import React from 'react';
import styled from 'styled-components';
import { slidingAnimation } from 'styles/utilities/index';
import Webflow from 'svgs/services/webflow.svg';

const SAnimatedWebflow = styled.div`
    width: 100%;
    height: 100%;
    #arrow {
        ${slidingAnimation(30, 0)};
    }
    #screen {
        ${slidingAnimation(-20, 0)};
    }
    #screen-shadow {
        ${slidingAnimation(-15, 0)};
    }
    #shape-1 {
        ${slidingAnimation(-5, -15)};
    }
    #shape-2 {
        ${slidingAnimation(-8, 5)};
    }
    #cursor {
        ${slidingAnimation(-7, -7)};
    }
`;

export const AnimatedWebflow = () => {
    return (
        <SAnimatedWebflow>
            <Webflow width="100%" height="100%" />
        </SAnimatedWebflow>
    );
};
