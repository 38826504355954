import React from 'react';
import { useIntl } from 'react-intl';
import { CtaSectionExtended } from 'modules/cta-section-extended';

import { CTA_BENEFITS } from '../constants';

export const WebflowAgencyCtaSection = () => {
    const { formatMessage } = useIntl();
    return (
        <CtaSectionExtended title="view-webflow-agency.cta-section.title">
            <ul>
                {CTA_BENEFITS.map((benefitKey) => (
                    <li key={benefitKey}>
                        {formatMessage({
                            id: `view-webflow-agency.cta-section.benefits.${benefitKey}`,
                        })}
                    </li>
                ))}
            </ul>
        </CtaSectionExtended>
    );
};
