import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderFirst } from 'components/header-first';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { SectionTiles } from 'components/section-tiles';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { ProcessLine } from 'modules/process-section/components/process-line';

import { OUR_PROCESS_DATA, WHY_WEBFLOW_TILES_DATA } from '../constants';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 2000px;
`;

const SSubContainer = styled.div`
    background-color: var(--gray-color-90);
    padding: 6.25rem 3.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .margin-bottom {
        margin-bottom: 4.5rem;
    }

    .text-left {
        text-align: left;
    }

    ${CONSTANTS.MEDIA.max768`
        padding: 6.25rem 2.375rem;
    `};

    ${CONSTANTS.MEDIA.max420`
        padding: 6.25rem 1.5rem;
    `};
`;

const SHeaderFistContainer = styled.div`
    text-align: center;
    margin-bottom: 6.25rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 4rem;
    `}

    ${CONSTANTS.MEDIA.max600`
        margin-bottom: 3rem;
    `}
`;

const SHeaderFirst = styled(HeaderFirst)`
    margin-bottom: 1.5rem;
`;

const SParagraph = styled(Paragraph)`
    font-weight: 600;
`;
const SHeaderSecondContainer = styled.div`
    text-align: center;
    margin-bottom: 3.75rem;
`;

const SSecondParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
`;

const STiles = styled(SectionTiles)`
    gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .tile {
        max-width: 17rem;
    }

    margin-bottom: 12.5rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 6rem;
    `}

    ${CONSTANTS.MEDIA.max500`
        margin-bottom: 4rem;
    `};
`;

const SProcessLine = styled(ProcessLine)`
    margin-top: 2rem;
    width: 100%;
`;

export const ExpertiseSection = () => {
    const { kebabCaseId: processId, handleCopyUrl: handleCopyProcessUrl } =
        useTranslatedTitle('view-webflow-agency.expertise-section.title');

    const {
        kebabCaseId: whatWeOfferId,
        handleCopyUrl: handleCopyWhatWeOfferUrl,
    } = useTranslatedTitle(
        'view-webflow-agency.expertise-section.what-we-offer.title',
    );

    return (
        <SContainer>
            <SSubContainer>
                <SHeaderFistContainer>
                    <SHeaderFirst>
                        <FormattedMessage id="view-webflow-agency.expertise-section.title" />
                    </SHeaderFirst>
                    <SParagraph>
                        <FormattedMessage id="view-webflow-agency.expertise-section.description" />
                    </SParagraph>
                </SHeaderFistContainer>

                <SHeaderSecondContainer>
                    <HeaderSecond
                        id={whatWeOfferId}
                        handleCopyUrl={handleCopyWhatWeOfferUrl}
                    >
                        <FormattedMessage id="view-webflow-agency.expertise-section.what-we-offer.title" />
                    </HeaderSecond>
                </SHeaderSecondContainer>
                <STiles tilesData={WHY_WEBFLOW_TILES_DATA} isOnDarkBackground />

                <SHeaderSecondContainer>
                    <HeaderSecond
                        id={processId}
                        handleCopyUrl={handleCopyProcessUrl}
                    >
                        <FormattedMessage id="view-webflow-agency.expertise-section.our-process.title" />
                    </HeaderSecond>
                    <SSecondParagraph>
                        <FormattedMessage id="view-webflow-agency.expertise-section.our-process.description" />
                    </SSecondParagraph>
                </SHeaderSecondContainer>
                <SProcessLine data={OUR_PROCESS_DATA} />
            </SSubContainer>
        </SContainer>
    );
};
