import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { string } from 'prop-types';
import TickCircleIcon from 'svgs/icons/ic-tick-circle.svg';

const SContainer = styled.li`
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
`;

const STitle = styled(Paragraph)`
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    font-weight: 700;
`;

const SDescription = styled(Paragraph)`
    font-size: 1rem;
`;

const STickCircleIcon = styled(TickCircleIcon)`
    flex-shrink: 0;
`;

export const StoryItem = ({ title, description }) => {
    return (
        <SContainer>
            <STickCircleIcon width={32} height={32} />
            <div>
                <STitle>
                    <FormattedMessage id={title} />
                </STitle>
                <SDescription>
                    <FormattedMessage id={description} />
                </SDescription>
            </div>
        </SContainer>
    );
};

StoryItem.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
};
