import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { SectionTiles } from 'components/section-tiles';
import { useTranslatedTitle } from 'hooks/use-translated-title';

import { BENEFITS_TILES_DATA } from '../constants';

const SHeader = styled(HeaderSecond)`
    text-align: center;
    size: 2.25rem;
    margin-bottom: 3.75rem;
`;

const STiles = styled(SectionTiles)`
    gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .tile {
        max-width: 19rem;
    }
`;

export const BenefitsSection = () => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(
        'view-webflow-agency.benefits-section.title',
    );

    return (
        <Container>
            <SHeader id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                <FormattedMessage id="view-webflow-agency.benefits-section.title" />
            </SHeader>
            <STiles tilesData={BENEFITS_TILES_DATA} />
        </Container>
    );
};
